<template>
  <div class="scroll-slider">
    <div ref="scrollContainer" class="scroll-container">
      <div class="inner-container">
        <ul ref="scrollContainerList">
          <li
            v-for="(item, index) in items"
            :key="item.id"
            ref="scrollItem"
            class="item"
          >
            <slot name="scroll-slider-item" :item="{ item, index }"></slot>
          </li>
        </ul>
      </div>
    </div>

    <div class="scroll-slider-control">
      <IconButton
        v-show="!isScrollStart"
        class="scroll-slider-control__start"
        icon="doubleArrow"
        height="32"
        width="32"
        @click="scroll(0)"
      />
      <IconButton
        class="scroll-slider-control__prev"
        icon="arrow"
        height="32"
        width="32"
        :disabled="isScrollStart"
        @click="scroll(-1)"
      />
      <IconButton
        class="scroll-slider-control__next"
        icon="arrow"
        height="32"
        width="32"
        :disabled="isScrollEnd"
        @click="scroll(1)"
      />
    </div>
    <slot name="modal"></slot>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import IconButton from "~/components/button/IconButton.vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import { NavItem } from "@nuxt/content/types";
import { IPicture } from "~/types/picture.interface";

export interface IScrollSliderItem {
  name?: string;
  imageUrl?: string;
  icon?: string;
  description?: string;
  id?: string;
  feedback?: string;
  link?: string;
  image?: IPicture;
}

export type ScrollSliderNavItem = IScrollSliderItem | NavItem;

export default defineNuxtComponent({
  components: { BaseIcon, IconButton },
  props: {
    items: {
      type: Array as PropType<ScrollSliderNavItem[]>,
      required: true,
    },
  },
  setup() {
    const { gtag } = useGtag();

    const scrollContainer = ref<HTMLElement | null>(null);
    const scrollContainerList = ref<HTMLElement | null>(null);
    const scrollItem = ref<HTMLElement[] | null>(null);

    const isScrollStart = ref<boolean>(true);
    const isScrollEnd = ref<boolean>(false);
    const debounceTimeout = ref<NodeJS.Timeout | null>(null);

    const spaceBetweenSlides = ref(0);

    function handleScrollEnd() {
      if (scrollContainer.value) {
        isScrollStart.value = scrollContainer.value.scrollLeft <= 0;

        isScrollEnd.value =
          Math.ceil(
            scrollContainer.value.clientWidth + scrollContainer.value.scrollLeft
          ) >= scrollContainer.value.scrollWidth;
      }
    }
    function debounceSearch() {
      if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
      debounceTimeout.value = setTimeout(() => {
        handleScrollEnd();
      }, 100);
    }

    const scroll = (direction: number) => {
      if (!scrollItem.value || !scrollContainer.value) {
        return;
      }

      let innerWidthSlide = scrollItem.value[0].clientWidth;

      if (direction === 0) {
        scrollContainer.value?.scrollTo({ left: 0, behavior: "smooth" });
        gtag("event", ScrollSliderEvents.start);
        return;
      }

      if (direction === 1) {
        const scrollOffset =
          scrollContainer.value.scrollWidth -
          scrollContainer.value.clientWidth -
          scrollContainer.value.scrollLeft;

        innerWidthSlide = Math.ceil(
          Math.min(scrollOffset, innerWidthSlide + spaceBetweenSlides.value)
        );

        gtag("event", ScrollSliderEvents.next);
      }

      if (direction === -1) {
        innerWidthSlide = Math.ceil(
          Math.min(
            scrollContainer.value.scrollLeft,
            Math.abs(innerWidthSlide) + spaceBetweenSlides.value
          )
        );

        gtag("event", ScrollSliderEvents.prev);
      }

      const distance = direction * innerWidthSlide;
      scrollContainer.value?.scrollBy({ left: distance, behavior: "smooth" });
    };

    onMounted(() => {
      if (scrollContainer.value) {
        scrollContainer.value.onscroll = () => {
          debounceSearch();
        };
      }

      if (scrollContainerList.value) {
        spaceBetweenSlides.value = parseInt(
          window
            .getComputedStyle(scrollContainerList.value)
            .getPropertyValue("gap")
        );
      }
    });

    return {
      scrollContainer,
      scrollContainerList,
      scrollItem,
      isScrollStart,
      isScrollEnd,
      scroll,
      handleScrollEnd,
    };
  },
});
</script>
